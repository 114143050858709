.button-primary {
  border-radius: .4rem;
  background-color: rgba(0, 118, 255, 0.1);
  display: inline-block;

  font-size: 1.5rem;
  font-weight: 500;
  color: rgba(16, 6, 159, 1);


  padding: 1rem 2rem 1rem;
  text-decoration: none;

  &__fluid {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
  }
}