.header-default {
  & * {
    color: #000;
  }

  & {
    width: 1170px;
    line-height: unset;
    padding: 0px 0px 70px 0px;
    margin: 0px auto;
  }

  &__logo {
    margin-top: 40px;
  }
  
  &__title {
    font-family: "ProximaNova";
    font-size: 52px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.26px;
    color: rgba(224, 0, 77,  1);
    margin-top: 11px;
  }
  
  &__content {
    font-family: "ProximaNova";
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.11px;
    color: #000000;
    margin-top: 7px;
  }
  
  &__subtitle {
    font-family: "ProximaNova";
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.12px;
    color: #000000;
  }
  
  &__rectangle {
    width: 110px;
    height: 4px;
    border-radius: 2px;
    background-color: #ff4538;
    margin-top: 30px;
  }
  
  &__image {
    width: 348px;
    height: 184px;
    object-fit: contain;
  }
  
  &__image_section {
    text-align: center;
  }
}
.coach {
  &-header {
    &-button {
      display: inline-block;
      position: relative;
      cursor: pointer;
      outline: none;
      height: 40px;
      line-height: 40px;
      border: solid 1px #a0a6cc;
      align-self: flex-end;
      border-radius: 20px !important;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.1px;
      text-align: center;
      color: #a0a6cc;
      padding: 0px 15px 0px 15px;
      text-transform: initial;
      -webkit-tap-highlight-color: transparent;
    }
    &-link {
      display: inline-block;
      position: relative;
      cursor: pointer;
      outline: none;
      height: 40px;
      line-height: 40px;
      //border: solid 1px #a0a6cc;
      align-self: flex-end;
      border-radius: 20px !important;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.1px;
      text-align: center;
      color: #a0a6cc;
      padding: 0px 15px 0px 15px;
      text-transform: initial;
      -webkit-tap-highlight-color: transparent;
    }
    &-transparent {
      background-color: transparent;
    }
    &-right {
      float: right;
    }
    &-none-top {
      margin-top: 0;
    }
  }
}
.coachHeader_button {
  border: solid 1px #a0a6cc;
  background-color: transparent;
  align-self: flex-end;
  border-radius: 20px !important;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.1px;
  text-align: center;
  color: #a0a6cc;
  padding: 0px 15px 0px 15px;
  font-family: "ProximaNova";
  text-transform: initial;
  float: right;
  margin-top: 0;
}
.help-modal-container {
  background: white;
  border-radius: 5px;
  margin: 0 auto;
  box-shadow: 0 0.3px 5px 0 rgba(0, 0, 0, 0.25);
  font-family: 'WorkSans' !important;
  font-weight: 500 !important;
}
.ReactModal__Overlay {
  .help-container {
    height: auto;
  }
}
.help-modal {
  margin:36px;
  .title {
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.12px;
  }
  .content {
    border: solid 0.5px rgba(0, 0, 0, 0.15);
    padding: 19px 17px;
    margin-top: 30px;
    p {
      font-size: 12px;
      font-weight: normal;
      color: #000000;
      letter-spacing: -0.28px;
    }
    p:first-child {
      margin-bottom: 28px
    }
    a {
      color: rgba(16, 6, 159, 1);
    }
  }
  .close-help {
    font-size: 17px;
    letter-spacing: -0.11px;
    color: rgba(16, 6, 159, 1);
    float: right;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  &-footer {
    display: flex;
    justify-content: flex-end;
  }
  &-header {
    display: flex;
    justify-content: flex-start;
  }
  @media screen and (max-width: 48em) {
    &-footer, &-header {
      justify-content: center;
    }
  }
}
@media screen and (max-width: 48em) {
  .help-modal {
    margin: 0px 10px;
  }
}
@media screen and (max-width: 319px) {
  .header-default {
    & {
      display: none;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 766px) {
  .header-default {
    & {
      min-width: 300px;
      max-width: 90vw;
      padding: 0px 0px 20px 0px;
    }

    &__image_section {
      padding-top: 30px;
    }
  
    &__title {
      font-size: 32px;
    }
  
    &__subtitle {
      font-size: 17px;
    }
  
    &__content {
      font-size: 14px;
    }
  
    &__image {
      width: 248px;
      object-fit: contain;
    }
  }
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .header-default {
    & {
      min-width: 700px;
      max-width: 90vw;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1025px) {
  .header-default {
    & {
      min-width: 1000px;
      max-width: 95vw;
    }
  }
}
