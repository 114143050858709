.profile {
	text-align: center;
	display: inline-flex;
	letter-spacing: normal;
	width: 100%;
}

.profile-block {
	display: inline-flex;
}

.profile .profile-content {
	padding: 20px;
	margin: 20px 10px;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	background-color: white;
	width: 100%;
}

.profile a {
	color: #000;
}

.profile-avatar {
	position: relative;
}

.profile-avatar__icf-badge {
	position: absolute;
	right: 0px;
	bottom: 0px;
}

.profile-avatar__picture {
	border-radius: 50%;
	width: 100%;
	box-shadow: 0 4px 32px 0 rgba(0, 0, 0, .24);
}

.profile__location-text {
	color: #00C1D5;
	font-size: 16px;
}

.profile__button-group a {
	color: white;
}

.profile__avatar {
	max-width: 50%;
	justify-content: center;
	margin: 0 auto;
}

.profile__fullname {
	text-shadow: none;
	color: #444;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.5;
}

.profile__description {
	display: block;
	line-height: initial;
	clear: both;
	font-size: 16px;
	font-weight: 700;
	color: #555;
	margin-bottom: 5px;
}

/* ----------------------------------------------------------------
	Buttons
-----------------------------------------------------------------*/

.button {
	display: inline-block;
	position: relative;
	cursor: pointer;
	outline: none;
	white-space: nowrap;
	margin: 5px;
	padding: 0 22px;
	font-size: 14px;
	height: 40px;
	line-height: 40px;
	background-color: #ea7454;
	color: #FFF;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
	border: none;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

body:not(.device-touch) .button {
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.button-dark {
	background-color: #444;
}

.button-light {
	background-color: #EEE;
	color: #333;
	text-shadow: none !important;
}

.button:hover {
	background-color: #7f5faa;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.button.button-dark:hover {
	background-color: #ee785a;
}

.button.button-mini {
	padding: 0 14px;
	font-size: 11px;
	height: 28px;
	line-height: 28px;
}

.button.button-small {
	padding: 0 17px;
	font-size: 12px;
	height: 34px;
	line-height: 34px;
}

.button.button-large {
	padding: 0 26px;
	font-size: 16px;
	height: 46px;
	line-height: 46px;
}

.button.button-xlarge {
	padding: 0 32px;
	font-size: 18px;
	letter-spacing: 2px;
	height: 52px;
	line-height: 52px;
}

.button.button-desc {
	text-align: left;
	padding: 24px 34px;
	font-size: 22px;
	height: auto;
	line-height: 1;
}

.button.button-desc span {
	display: block;
	margin-top: 10px;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 1px;
	font-style: italic;
	text-transform: none;
}

.button i {
	position: relative;
	top: 1px;
	line-height: 1;
	margin-right: 5px;
	width: 16px;
	text-align: center;
}

.button.tright i {
	margin: 0 0 0 5px;
}

.button-mini i {
	margin-right: 3px;
}

.button-mini.tright i {
	margin: 0 0 0 3px;
}

.button-small i {
	margin-right: 4px;
}

.button-small.tright i {
	margin: 0 0 0 4px;
}

.button-xlarge i {
	top: 2px;
	margin-right: 8px;
}

.button-xlarge.tright i {
	margin: 0 0 0 8px;
}

.button.button-desc i {
	top: 1px;
	font-size: 48px;
	width: 48px;
	text-align: center;
	margin-right: 12px;
}

.button.button-desc.tright i {
	margin: 0 0 0 12px;
}

.button.button-desc div {
	display: inline-block;
}

.button.button-rounded {
	border-radius: 3px;
}

/* Buttons - Border
-----------------------------------------------------------------*/

.button.button-border {
	border: 2px solid #444;
	background-color: transparent;
	color: #333;
	line-height: 36px;
	font-weight: 700;
	text-shadow: none;
}

.button.button-border-thin {
	border-width: 1px;
	line-height: 38px;
}

.button.button-border.button-mini {
	line-height: 24px;
}

.button.button-border.button-small {
	line-height: 30px;
}

.button.button-border.button-large {
	line-height: 42px;
}

.button.button-border.button-xlarge {
	line-height: 48px;
}

.button.button-border-thin.button-mini {
	line-height: 26px;
}

.button.button-border-thin.button-small {
	line-height: 32px;
}

.button.button-border-thin.button-large {
	line-height: 44px;
}

.button.button-border-thin.button-xlarge {
	line-height: 50px;
}

.button.button-border.button-desc {
	line-height: 1;
}

.button.button-border:not(.button-fill):hover {
	background-color: #444;
	color: #FFF;
	border-color: transparent !important;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.button.button-border.button-light {
	border-color: #EEE;
	color: #EEE;
}

.button.button-border.button-light:hover {
	background-color: #EEE;
	color: #333;
	text-shadow: none;
}

/* Buttons - Border
-----------------------------------------------------------------*/

.button.button-3d {
	border-radius: 3px;
	border-bottom: 3px solid rgba(0, 0, 0, 0.15);
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}

.button.button-3d:hover {
	background-color: #54a5a6 !important;
	opacity: 0.9;
}

.button.button-3d.button-light:hover, .button.button-reveal.button-light:hover {
	text-shadow: none;
	color: #333;
}

/* Buttons - Icon Reveal
-----------------------------------------------------------------*/

.button.button-reveal {
	padding: 0 28px;
	overflow: hidden;
}

.button.button-reveal i {
	display: block;
	position: absolute;
	top: 0;
	left: -32px;
	width: 32px;
	height: 40px;
	line-height: 40px;
	margin: 0;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.1);
}

.button.button-reveal.button-border i {
	top: -2px;
}

.button.button-reveal.tright i {
	left: auto;
	right: -32px;
}

.button.button-reveal.button-3d i, .button.button-reveal.button-rounded i {
	border-radius: 3px 0 0 3px;
}

.button.button-reveal.button-3d.tright i, .button.button-reveal.button-rounded.tright i {
	border-radius: 0 3px 3px 0;
}

.button.button-reveal span {
	display: inline-block;
	position: relative;
	left: 0;
}

body:not(.device-touch) .button.button-reveal i, body:not(.device-touch) .button.button-reveal span {
	-webkit-transition: left 0.3s ease, right 0.3s ease;
	-o-transition: left 0.3s ease, right 0.3s ease;
	transition: left 0.3s ease, right 0.3s ease;
}

.button.button-reveal.button-mini {
	padding: 0 17px;
}

.button.button-reveal.button-mini i {
	left: -22px;
	width: 22px;
	height: 28px;
	line-height: 28px;
}

.button.button-reveal.button-mini.tright i {
	left: auto;
	right: -22px;
}

.button.button-reveal.button-small {
	padding: 0 22px;
}

.button.button-reveal.button-small i {
	left: -26px;
	width: 26px;
	height: 34px;
	line-height: 34px;
}

.button.button-reveal.button-small.tright i {
	left: auto;
	right: -26px;
}

.button.button-reveal.button-large {
	padding: 0 32px;
}

.button.button-reveal.button-large i {
	left: -38px;
	width: 38px;
	height: 46px;
	line-height: 46px;
}

.button.button-reveal.button-large.tright i {
	left: auto;
	right: -38px;
}

.button.button-reveal.button-xlarge {
	padding: 0 40px;
}

.button.button-reveal.button-xlarge i {
	left: -44px;
	width: 44px;
	height: 52px;
	line-height: 52px;
}

.button.button-reveal.button-xlarge.tright i {
	left: auto;
	right: -44px;
}

.button.button-reveal:hover i {
	left: 0;
}

.button.button-reveal.tright:hover i {
	left: auto;
	right: 0;
}

.button.button-reveal:hover span {
	left: 16px;
}

.button.button-reveal.button-mini:hover span {
	left: 11px;
}

.button.button-reveal.button-small:hover span {
	left: 13px;
}

.button.button-reveal.button-large:hover span {
	left: 19px;
}

.button.button-reveal.button-xlarge:hover span {
	left: 22px;
}

.button.button-reveal.tright:hover span {
	left: -16px;
}

.button.button-reveal.button-mini.tright:hover span {
	left: -11px;
}

.button.button-reveal.button-small.tright:hover span {
	left: -13px;
}

.button.button-reveal.button-large.tright:hover span {
	left: -19px;
}

.button.button-reveal.button-xlarge.tright:hover span {
	left: -22px;
}

/* Buttons - Promo 100% Width
-----------------------------------------------------------------*/

.button.button-full {
	display: block;
	width: 100%;
	white-space: normal;
	margin: 0;
	height: auto;
	line-height: 1.6;
	padding: 30px 0;
	font-size: 30px;
	font-weight: 300;
	text-transform: none;
	border-radius: 0;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.button.button-full.button-light {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.button.button-full strong {
	font-weight: 700;
	border-bottom: 2px solid #EEE;
}

body:not(.device-touch) .button.button-full strong {
	-webkit-transition: border .2s ease-in-out;
	-o-transition: border .2s ease-in-out;
	transition: border .2s ease-in-out;
}

.button.button-full.button-light strong {
	border-bottom-color: #333;
}

.button.button-full.button-light:hover strong {
	border-bottom-color: #EEE;
}

/* Buttons - Circle
-----------------------------------------------------------------*/

.button.button-circle {
	border-radius: 20px;
}

.button.button-mini.button-circle {
	border-radius: 14px;
}

.button.button-small.button-circle {
	border-radius: 17px;
}

.button.button-large.button-circle {
	border-radius: 23px;
}

.button.button-xlarge.button-circle {
	border-radius: 26px;
}

/* Buttons - Fill Effect
-----------------------------------------------------------------*/

.button.button-border.button-fill {
	overflow: hidden;
	transform-style: preserve-3d;
	-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
	-webkit-backface-visibility: hidden;
}

.button.button-border.button-fill span {
	position: relative;
}

.button.button-border.button-fill:hover {
	background-color: transparent !important;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.button.button-border.button-fill.button-light:hover {
	border-color: #EEE !important;
	color: #333 !important;
	text-shadow: none;
}

.button.button-border.button-fill:before {
	content: '';
	position: absolute;
	background-color: #444;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	-webkit-transition: all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease;
	-webkit-backface-visibility: hidden;
}

.button.button-border.button-fill.button-light:before {
	background-color: #EEE;
}

.button.button-border.button-fill.fill-from-right:before {
	left: auto;
	right: 0;
}

.button.button-border.button-fill:hover:before {
	width: 100%;
}

.button.button-border.button-fill.fill-from-top:before, .button.button-border.button-fill.fill-from-bottom:before {
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.button.button-border.button-fill.fill-from-top:before {
	width: 100%;
	height: 0;
}

.button.button-border.button-fill.fill-from-top:hover:before {
	height: 100%;
}

.button.button-border.button-fill.fill-from-bottom:before {
	width: 100%;
	height: 0;
	top: auto;
	bottom: 0;
}

.button.button-border.button-fill.fill-from-bottom:hover:before {
	height: 100%;
}

/* Buttons - Colors
-----------------------------------------------------------------*/

.button-teal {
	background-color: #53777A;
}

.button-3d.button-teal:hover, .button-reveal.button-teal:hover, .button-border.button-teal:hover, .button-border.button-teal.button-fill:before {
	background-color: #53777A !important;
}

.button-border.button-teal {
	color: #53777A;
	border-color: #53777A;
}

.button-yellow {
	background-color: #ECD078;
}

.button-3d.button-yellow:hover, .button-reveal.button-yellow:hover, .button-border.button-yellow:hover, .button-border.button-yellow.button-fill:before {
	background-color: #ECD078 !important;
}

.button-border.button-yellow, .button-border.button-yellow.button-fill.button-light:hover {
	color: #ECD078 !important;
	border-color: #ECD078 !important;
}

.button-border.button-yellow:hover, .button-border.button-yellow.button-fill.button-light:hover {
	color: #333 !important;
}

.button-green {
	background-color: #59BA41;
}

.button-3d.button-green:hover, .button-reveal.button-green:hover, .button-border.button-green:hover, .button-border.button-green.button-fill:before {
	background-color: #59BA41 !important;
}

.button-border.button-green {
	color: #59BA41;
	border-color: #59BA41;
}

.button-brown {
	background-color: #7b54bf;
}

.button-3d.button-brown:hover, .button-reveal.button-brown:hover, .button-border.button-brown:hover, .button-border.button-brown.button-fill:before {
	background-color: #f39375!important;
}

.button-border.button-brown {
	color: #f39375;
	border-color: #f39375;
}

.button-aqua {
	background-color: #40C0CB;
}

.button-3d.button-aqua:hover, .button-reveal.button-aqua:hover, .button-border.button-aqua:hover, .button-border.button-aqua.button-fill:before {
	background-color: #40C0CB !important;
}

.button-border.button-aqua {
	color: #40C0CB;
	border-color: #40C0CB;
}

.button-lime {
	background-color: #AEE239;
}

.button-3d.button-lime:hover, .button-reveal.button-lime:hover, .button-border.button-lime:hover, .button-border.button-lime.button-fill:before {
	background-color: #AEE239 !important;
}

.button-border.button-lime {
	color: #AEE239;
	border-color: #AEE239;
}

.button-mandarine {
	background-color: #FF5851;
}

.button-3d.button-mandarine:hover, .button-reveal.button-mandarine:hover, .button-border.button-mandarine:hover, .button-border.button-mandarine.button-fill:before {
	background-color: #FF5851 !important;
}

.button-border.button-mandarine {
	color: #ea7454;
	border-color: #ea7454;
}

.button-grenache {
	background-color: #8c426a;
}

.button-3d.button-grenache:hover, .button-reveal.button-grenache:hover, .button-border.button-grenache:hover, .button-border.button-grenache.button-fill:before {
	background-color: #9595D2 !important;
}

.button-border.button-grenache {
	color: #8c426a;
	border-color: #8c426a;
}

.button-purple {
	background-color: #9595D2;
}

.button-3d.button-purple:hover, .button-reveal.button-purple:hover, .button-border.button-purple:hover, .button-border.button-purple.button-fill:before {
	background-color: #9595D2 !important;
}

.button-border.button-purple {
	color: #876fa7;
	border-color: #876fa7;
}

.button-leaf {
	background-color: #A8CABA;
}

.button-3d.button-leaf:hover, .button-reveal.button-leaf:hover, .button-border.button-leaf:hover, .button-border.button-leaf.button-fill:before {
	background-color: #A8CABA !important;
}

.button-border.button-leaf {
	color: #A8CABA;
	border-color: #A8CABA;
}

.button-pink {
	background-color: #F89FA1;
}

.button-3d.button-pink:hover, .button-reveal.button-pink:hover, .button-border.button-pink:hover, .button-border.button-pink.button-fill:before {
	background-color: #F89FA1 !important;
}

.button-border.button-pink {
	color: #F89FA1;
	border-color: #F89FA1;
}

.button-dirtygreen {
	background-color: #2299a3;
}

.button-3d.button-dirtygreen:hover, .button-reveal.button-dirtygreen:hover, .button-border.button-dirtygreen:hover, .button-border.button-dirtygreen.button-fill:before {
	background-color: #2299a3 !important;
}

.button-border.button-dirtygreen {
	color: #2299a3;
	border-color: #2299a3;
}

.button-blue {
	background-color: #00C1D5;
}

.button-3d.button-blue:hover, .button-reveal.button-blue:hover, .button-border.button-blue:hover, .button-border.button-blue.button-fill:before {
	background-color: #00C1D5 !important;
}

.button-blue {
	background-color: #1265A8;
}

.button-3d.button-blue:hover, .button-reveal.button-blue:hover, .button-border.button-blue:hover, .button-border.button-blue.button-fill:before {
	background-color: #1265A8 !important;
}

.button-border.button-blue {
	color: #1265A8;
	border-color: #1265A8;
}

.button-amber {
	background-color: #EB9C4D;
}

.button-3d.button-amber:hover, .button-reveal.button-amber:hover, .button-border.button-amber:hover, .button-border.button-amber.button-fill:before {
	background-color: #EB9C4D !important;
}

.button-border.button-amber {
	color: #EB9C4D;
	border-color: #EB9C4D;
}

.button-black {
	background-color: #111;
}

.button-3d.button-black:hover, .button-reveal.button-black:hover, .button-border.button-black:hover, .button-border.button-black.button-fill:before {
	background-color: #111 !important;
}

.button-border.button-black {
	color: #111;
	border-color: #111;
}

.button-white {
	background-color: #F9F9F9;
}

.button-3d.button-white:hover, .button-reveal.button-white:hover {
	background-color: #F9F9F9 !important;
}

/* Buttons - No Hover
-----------------------------------------------------------------*/

.button.button-nohover:hover {
	opacity: inherit !important;
	background-color: inherit !important;
	color: inherit !important;
	border-color: inherit !important;
}

.coachSearch_button {
	align-self: flex-end;
	display: inline-block;
	border-radius: 20px !important;
	padding: 0 40px !important;
	background-image: linear-gradient(to right, rgba(224, 0, 77,  1), rgba(16, 6, 159, 1));
}

.coachSearch_button:hover {
	background-image: linear-gradient(to right, rgba(224, 0, 77, 0.8), rgba(16, 6, 159, 0.8));
}

@media (max-width: 767px) {
	.coachSearch_button {
		display: block;
		width: 100%;
		border-radius: 20px !important;
		text-align: center;
		padding: 0 10px 0 0;
	}
}