
.card-primary {
  & {
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0 .2rem .7rem 0 #d4d9f7;
    border: solid .05rem #caccd8;
    //width: 40rem;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__disabled {
    opacity: 0.3;
    a {
      cursor: default;
    }
  }
  

  &__wrap {
    padding: 1.2rem;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__image {
    width: 100%;
    height: 100%;
  }
}

// @media screen and (min-width: 1081px) and (max-width: 1400px) {
//     .card-primary {
//       max-width: 32rem;
//     }
// }
// @media screen and (min-width: 1025px) and (max-width: 1080px) {
//   .card-primary {
//     max-width: 28rem;
//   }
// }

// @media screen and (min-width: 801px) and (max-width: 1024px) {
//   .card-primary {
//     max-width: 20rem;
//   }
// }

// @media screen and (min-width: 769px) and (max-width: 800px) {
//   .card-primary {
//     max-width: 20rem;
//   }
// }

// @media screen and (max-width: 768px) {
//   .card-primary {
//     max-width: 15.8rem;
//   }
// }
